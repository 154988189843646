import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import { MdOpenInNew } from "react-icons/md";
import Button from "../../components/Button";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

const query = graphql`
  {
    psicoterapiaIndividuale: file(
      base: { eq: "psicoterapia-individuale.jpg" }
      sourceInstanceName: { eq: "assets" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
      }
    }
    sostegnoPsicologico: file(
      base: { eq: "sostegno-psicologico.jpg" }
      sourceInstanceName: { eq: "assets" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
      }
    }
    consulenzaPsicologica: file(
      base: { eq: "consulenza-psicologica.jpg" }
      sourceInstanceName: { eq: "assets" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
      }
    }
    valutazionePsicodiagnostica: file(
      base: { eq: "valutazione-psicodiagnostica.jpg" }
      sourceInstanceName: { eq: "assets" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
      }
    }
  }
`;

const PanoramicaPage = () => {
  const {
    psicoterapiaIndividuale,
    sostegnoPsicologico,
    consulenzaPsicologica,
    valutazionePsicodiagnostica,
  } = useStaticQuery(query);

  return (
    <Layout>
      <Seo
        title="Panoramica"
        description="Chi sono: sono il Dott. Francesco Latini, psicologo psicoterapeuta iscritto all'Albo A dell'Ordine degli Psicologi della Lombardia (OPL). Cosa propongo: Psicoterapia Individuale, Sostegno Psicologico, Consulenza Psicologica, Valutazione Psicodiagnostica."
      />
      <section className="grid grid-cols-6 py-6">
        <article className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0">
          <h1 className="mb-3">Chi sono:</h1>
          <p>
            Sono il Dott. Francesco Latini, <b>psicologo psicoterapeuta</b> iscritto
            all'<b>Albo A</b> dell'<b>Ordine degli Psicologi della Lombardia</b>{" "}
            (<a href="https://www.opl.it/">OPL</a>). La mia formazione si è
            svolta prima sui banchi dell'<b>Università di Milano-Bicocca</b> (
            <a href="https://www.unimib.it/aree-disciplinari/psicologica">
              UNIMIB
            </a>
            ) ottenendo la laurea <i>cum laude</i>, proseguendo poi in un{" "}
            <b>Master biennale sulla Psicodiagnosi</b> presso lo Studio
            Associato <a href="https://www.arpmilano.it/">ARP</a> ed in{" "}
            <b>tirocini professionalizzanti</b> al{" "}
            <a href="https://carceredibollate.it/">Carcere di Bollate</a> e
            nelle Comunità Terapeutiche a Media Protezione (CPM) della{" "}
            <a href="https://www.fondazionelighea.it/">
              Fondazione Lighea Onlus
            </a>
            . Attualmente mi sto formando come psicoterapeuta presso la{" "}
            <b>Scuola di Psicoterapia Psicoanalitica</b> di Milano (
            <a href="https://www.sppscuoladipsicoterapia.it/corsi-milano/eta-adulta/organizzazione.html">
              SPP
            </a>
            ) svolgendo servizio al{" "}
            <a href="https://www.asst-santipaolocarlo.it/centro-giovani-ponti">
              Centro Giovani Ettore Ponti
            </a>{" "}
            dell'ASST Santi Paolo e Carlo di Milano.
          </p>
          <p className="mt-3">
            Oltre al lavoro in <b>studio privato</b>, collaboro con la{" "}
            <a href="https://www.coopcalypso.it/">Cooperativa Calypso</a> nel
            campo del <b>disagio psichico grave</b>.
          </p>
        </article>
        <article className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0 mt-6">
          <div className="flex md:justify-around flex-col md:flex-row">
            <a
              href="https://elencopsicologi.it/Francesco-Latini/?q=8631"
              className="text-center self-center mb-4 md:m-0"
              rel="noreferrer"
              target="_blank"
            >
              <StaticImage
                src="../../assets/social-icons/elencopsicologi.png"
                alt="Elenco Psicologi"
                height={72}
              />
              <div className="flex items-center">
                <span className="pr-2">Elenco Psicologi</span>
                <MdOpenInNew />
              </div>
            </a>
            <a
              href="https://www.psymap.it/scheda.php?idp=3313&origLat=&origLon="
              className="text-center self-center mb-4 md:m-0"
              rel="noreferrer"
              target="_blank"
            >
              <StaticImage
                src="../../assets/social-icons/psymap.png"
                alt="Psymap"
                height={72}
              />
              <div className="flex items-center">
                <span className="pr-2">Psymap</span>
                <MdOpenInNew />
              </div>
            </a>
            <a
              href="https://www.linkedin.com/in/francesco-latini-680075108"
              className="text-center self-center"
              rel="noreferrer"
              target="_blank"
            >
              <StaticImage
                src="../../assets/social-icons/linkedin.png"
                alt="LinkedIn"
                height={72}
              />
              <div className="flex items-center">
                <span className="pr-2">LinkedIn</span>
                <MdOpenInNew />
              </div>
            </a>
          </div>
        </article>
      </section>
      <section className="grid grid-cols-6 py-6">
        <article className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0">
          <h1>Cosa propongo:</h1>
          <ul className="list-disc list-outside pl-9 mt-3">
            <li>Psicoterapia Individuale</li>
            <li>Sostegno Psicologico</li>
            <li>Consulenza Psicologica</li>
            <li>Valutazione Psicodiagnostica</li>
          </ul>
          <p className="mt-2">
            Tali prestazioni sono pensate per soggetti <b>adulti</b> o{" "}
            <b>adolescenti</b>, in forma <b>vis-à-vis</b>. È comunque prevista
            anche la possibilità di svolgere sedute online tramite <b>Skype</b>.
          </p>
        </article>
      </section>
      <section className="grid grid-cols-6 py-6">
        <article className="col-span-6 md:col-start-2 md:col-span-2 xl:col-start-2 xl:col-span-1 px-5 pb-5 md:px-0 xl:p-0">
          <div className="md:px-3">
            <GatsbyImage
              image={psicoterapiaIndividuale.childImageSharp.gatsbyImageData}
              alt="Cover per Psicoterapia Individuale"
            />
            <h2 className="text-2xl text-center py-3">
              Psicoterapia Individuale
            </h2>
            <p className="text-justify">
              Si tratta di un processo di <b>cambiamento globale</b>, che
              riguarda la <b>struttura della personalità</b> coinvolgendo non
              solo gli aspetti "portatori di sofferenza" (<i>egodistonici</i>)
              ma anche quelli apparentemente funzionali ed accettati (
              <i>egosintonici</i>).
            </p>
            <br />
            <p className="text-justify">
              E' quindi un percorso <b>che va in profondità</b>, che{" "}
              <b>richiede tempo</b> e la disponibiltà a{" "}
              <b>mettersi in discussione</b>.
            </p>
          </div>
        </article>
        <hr className="col-span-6 md:hidden mb-8 mx-5 text-grey h-2" />
        <article className="col-span-6 md:col-span-2 xl:col-span-1 px-5 pb-5 md:px-0 xl:p-0">
          <div className="md:px-3">
            <GatsbyImage
              image={sostegnoPsicologico.childImageSharp.gatsbyImageData}
              alt="Cover per Sostegno Psicologico"
            />
            <h2 className="text-2xl text-center py-3">Sostegno Psicologico</h2>
            <p className="text-justify">
              E' rivolto al miglioramento del <b>benessere globale</b> della
              persona che viene aiutata a fronteggiare una situazione
              problematica, presente nel <i>qui ed ora</i> e in qualche modo
              inevitabile (es. passaggi esistenziali, momenti di crisi, eventi
              più o meno penosi o catastrofici, una malattia propria o di
              persone affettivamente vicine).
            </p>
            <br />
            <p className="text-justify">
              Si basa essenzialmente sull'ascolto ed il supporto emotivo ed è
              finalizzato ad{" "}
              <b>
                accompagnare la persona, accoglierne i bisogni emergenti,
                mobilitare risorse
              </b>
              , fornire suggerimenti utili alla <b>gestione</b> della vita
              quotidiana.
            </p>
          </div>
        </article>
        <hr className="col-span-6 md:col-span-4 md:col-start-2 xl:hidden mb-8 mx-5 md:mx-0 text-grey h-2" />
        <article className="col-span-6 md:col-start-2 md:col-span-2 xl:col-span-1 px-5 pb-5 md:px-0 xl:p-0">
          <div className="md:px-3">
            <GatsbyImage
              image={consulenzaPsicologica.childImageSharp.gatsbyImageData}
              alt="Cover per Consulenza Psicologica"
            />
            <h2 className="text-2xl text-center py-3">
              Consulenza Psicologica
            </h2>
            <p className="text-justify">
              Rispetto ad un intervento profondo e strutturale come nel caso
              della psicoterapia, in questo caso il focus dell'intervento
              riguarda un <b>problema ben definibile e circoscritto</b> che non
              invalida il senso della propria identità personale: si tratta
              quindi, spesso, di una difficoltà nell'effettuare delle scelte,
              nel prendere decisioni rispetto ad una situazione di{" "}
              <i>impasse</i> momentanea.
            </p>
            <br />
            <p className="text-justify">
              Si caratterizza quindi per un <b>intervento di breve durata</b> in
              cui lo psicologo, focalizzandosi insieme al paziente sullo
              specifico problema, lo aiuta a vagliare tutte le possibili
              soluzioni facilitando il processo decisionale e l'utilizzo delle
              risorse presenti.
            </p>
          </div>
        </article>
        <hr className="col-span-6 md:hidden mb-8 mx-5 text-grey h-2" />
        <article className="col-span-6 md:col-span-2 xl:col-span-1 px-5 pb-5 md:px-0 xl:p-0">
          <div className="md:px-3">
            <GatsbyImage
              image={
                valutazionePsicodiagnostica.childImageSharp.gatsbyImageData
              }
              alt="Cover per Valutazione Psicodiagnostica"
            />
            <h2 className="text-2xl text-center py-3">
              Valutazione Psicodiagnostica
            </h2>
            <p className="text-justify">
              Si tratta di un{" "}
              <b>approfondimento del funzionamento psicologico</b> finalizzato
              non tanto ad uno sterile etichettamento diagnostico quanto
              piuttosto ad aiutare il paziente a cogliere alcuni aspetti poco
              conosciuti di sè, non solo in termini di fragilità ma anche di
              risorse.
            </p>
            <br />
            <p className="text-justify">
              La <b>diagnosi</b> non dovrebbe mai essere un punto di arrivo ma
              piuttosto un punto di partenza utile per valutare il percorso
              migliore da intraprendere, consapevoli del fatto che{" "}
              <b>
                la complessità dell'individuo non potrà mai essere racchiusa
                dentro una parola o una sigla
              </b>
              .
            </p>
          </div>
        </article>
      </section>
      <section className="grid grid-cols-6 py-6">
        <hr className="col-span-6 md:col-start-2 md:col-span-4 mb-8 mx-5 text-grey h-2" />
        <article className="col-span-6 md:col-start-2 md:col-span-2 px-5 pb-10 md:px-0 xl:p-0 text-center">
          <Button text="Chiama ora" to="tel:+393922417807" external otherClasses="bg-yellow py-4" />
        </article>
        <article className="col-span-6 md:col-span-2 px-5 md:px-0 text-center">
          <Button text="Altri contatti" to="/contatti" otherClasses="py-4" />
        </article>
      </section>
    </Layout>
  );
};

export default PanoramicaPage;
